/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-return-await */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */

import axios from "axios";
import { adminRoot, servicePath } from "constants/defaultValues";
import { getCurrentEmail, setCurrentEmail, setCurrentUser, setCurrentUserName, setToken } from "helpers/Utils";
import { createBrowserHistory } from "history";
import { all, call, fork, put, select,takeEvery } from "redux-saga/effects";

import {
    CHECK_OTP_USER,
    CHECK_USER,
    FORGOT_PASSWORD,
    LOGIN_USER,
    LOGOUT_USER,
    RESET_PASSWORD,
} from "../contants";
import {
    checkOtpUserSuccess,
    forgotPasswordError,
    forgotPasswordSuccess,
    loginUserCheckSuccess,
    loginUserError,
    loginUserSuccess,
    resetPasswordError,
    resetPasswordSuccess,
} from "./actions";

export function* watchCheckOtpUser() {
    yield takeEvery(CHECK_OTP_USER, checkOtpLogin);
}

const checkOtpLoginAsync = async (email, code) =>
    await axios
        .post(`${servicePath}/auth/otp`, {
            email,
            otp: parseInt(code, 10),
            type: "DASHBOARD",
            action: "ACCOUNT_RESET_PASSWORD"
        })
        .then((data) => data)
        .catch((data) => data.response);

function* checkOtpLogin({ payload }) {
    const state = yield select();
    const email = state.authUser?.currentUser?.email ?? null;

    const { code, history } = payload;

    try {
        const response = yield call(
            checkOtpLoginAsync,
            email,
            code
        );

        if (response.status === 200) {
            if (response.data?.error) {
                yield put(loginUserError(`server.${response.data.message}`));

                return;
            }

            const { challenge } = response.data;

            yield put(checkOtpUserSuccess(challenge));
            history.push("/user/reset-password");
        } else if (response.status === 409) {
            yield put(loginUserError(`server.${response.data.message}`));
        } else if (response.status === 400) {
            yield put(loginUserError(response.data.message));
        } else {
            yield put(loginUserError("default-error"));
        }
    } catch (error) {
        yield put(loginUserError("default-error"));
    }
}

export function* watchCheckUser() {
    yield takeEvery(CHECK_USER, checkLogin);
}

const checkLoginAsync = async (login) =>
    await axios
        .post(`${servicePath}/auth/login`, {
            login: login.email,
            password: login.password,
            type: "DASHBOARD",
        })
        .then((user) => user)
        .catch((data) => data.response);

function* checkLogin({ payload }) {
    const { login } = payload;
    const { history } = payload;

    try {
        const response = yield call(checkLoginAsync, login);

        if (response.status === 200) {
            const { data } = response;

            switch (data.message) {
            case "USER_AUTH_FAILED":
                yield put(loginUserError('error.login.auth-failed'));

                break;
            default:
                setCurrentUserName(`${data.firstName} ${data.lastName}`);
                setToken(data.token);
                setCurrentUser(data);

                yield put(loginUserSuccess({
                    ...data,
                    userName: `${data.firstName} ${data.lastName}`,
                    email: login.email,
                }));

                if (data.firstLogin){
                    history.push("/user/reset-password");

                    return;
                }

                yield put(loginUserCheckSuccess(data.login, data?.status || 200));
                history.push(adminRoot);

                return;
            }
        } else if (response.status === 409) {
            yield put(loginUserError(`server.${response.data.message}`));
        } else if (response.status === 400) {
            yield put(loginUserError(response.data.message));
        } else {
            yield put(loginUserError("default-error"));
        }
    } catch (error) {
        yield put(loginUserError("default-error"));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, userLogin);
}

const userLoginAsync = async (username, password) =>
    await axios
        .post(`${servicePath}/auth/signin`, {
            username,
            password,
        })
        .then((response) => response)
        .catch((response) => response.response);

function* userLogin({ payload }) {
    const { username, password } = payload.user;
    const { history } = payload;

    try {
        const response = yield call(userLoginAsync, username, password);

        if (response.status === 200 && response.data) {
            setCurrentUser(response.data);
            yield put(loginUserSuccess(response.data));
            history.push(adminRoot);
        } else if (response.status === 409) {
            yield put(loginUserError(`server.${response.data.message}`));
        } else if (response.status === 400) {
            yield put(loginUserError(response.data.message));
        } else {
            yield put(loginUserError("default-error"));
        }
    } catch (error) {
        yield put(loginUserError("default-error"));
    }
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    if (history === null) {
        history = createBrowserHistory();
        history.push("/user/login");
        document.location.reload(); // force state :)
    } else {
        history.push("/user/login");
    }
};

function* logout({ payload }) {
    const { history } = payload;

    setCurrentUser();
    yield call(logoutAsync, history);
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (login, password, challenge) => await axios
    .post(`${servicePath}/auth/reset`, {
        login,
        secret: password,
        challenge,
        type: 'DASHBOARD',
        action: 'ACCOUNT_RESET_PASSWORD'
    })
    .then((data) => data)
    .catch((data) => data.response);

function* resetPassword({ payload }) {
    const { login, password, challenge, history } = payload;

    try {
        const response = yield call(
            resetPasswordAsync,
            login,
            password,
            challenge
        );

        if (response.status === 200 && response.data) {
            yield put(resetPasswordSuccess("success"));

            history.push('/user/login');
        } else if (response.status === 409) {
            yield put(loginUserError(`server.${response.data.message}`));
        } else if (response.status === 400) {
            yield put(loginUserError(response.data.message));
        } else {
            yield put(loginUserError("default-error"));
        }
    } catch (error) {
        yield put(resetPasswordError(error));
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (login) => await axios
    .post(`${servicePath}/auth/reset/send`, {
        email: login,
        type: "DASHBOARD",
    })
    .then((data) => data)
    .catch((data) => data.response);

function* forgotPassword({ payload }) {
    const { login } = payload;

    try {
        const response = yield call(forgotPasswordAsync, login);
        const { history } = payload;

        if (response.status === 200 && response.data) {
            setCurrentUserName(response.data.login);
            yield put(
                loginUserCheckSuccess(login, response.data.status)
            );
            history.push("/user/login/otp");
        } else if (response.status === 409) {
            yield put(loginUserError(`server.${response.data.message}`));
        } else if (response.status === 400) {
            yield put(loginUserError(response.data.message));
        } else {
            yield put(loginUserError("default-error"));
        }
    } catch (error) {
        yield put(resetPasswordError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchResetPassword),
        fork(watchCheckUser),
        fork(watchCheckOtpUser),
        fork(watchForgotPassword),
    ]);
}
