/*
export const Config = {
    domain: 'https://127.0.0.1:6066',
};

*/

export const Config = {
    domain: process.env.REACT_APP_TAOO_SERVER_URL,
};
