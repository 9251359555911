import {
    CHECK_OTP_USER,
    CHECK_OTP_USER_SUCCESS,
    CHECK_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_CHECK_SUCCESS,
    LOGIN_USER_ERROR,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
} from "../contants";

export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: { user, history },
});

export const checkUser = (login, history) => ({
    type: CHECK_USER,
    payload: { login, history },
});

export const checkOtpUser = (code, history) => ({
    type: CHECK_OTP_USER,
    payload: { code, history },
});

export const checkOtpUserSuccess = (challenge) => ({
    type: CHECK_OTP_USER_SUCCESS,
    payload: { challenge },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserCheckSuccess = (login, status) => ({
    type: LOGIN_USER_CHECK_SUCCESS,
    payload: { login, status },
});

export const loginUserError = (message) => ({
    type: LOGIN_USER_ERROR,
    payload: { message },
});

export const forgotPassword = (login, history) => ({
    type: FORGOT_PASSWORD,
    payload: { login, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
    type: FORGOT_PASSWORD_ERROR,
    payload: { message },
});

export const resetPassword = ({
    login,
    password,
    challenge,
    history,
}) => ({
    type: RESET_PASSWORD,
    payload: { login, password, challenge, history },
});
export const resetPasswordSuccess = (newPassword) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: newPassword,
});
export const resetPasswordError = (message, resetPasswordCode) => ({
    type: RESET_PASSWORD_ERROR,
    payload: { message, resetPasswordCode },
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});
