import { Config } from 'config';

export const UserRole = {
    ROLE_TAOO_CLIENT: 'ROLE_CLIENT',
    ROLE_TAOO_ADMIN: 'ROLE_TAOO_ADMIN',
    ROLE_TAOO_OPERATOR: 'ROLE_TAOO_OPERATOR',
    ROLE_CASHIER: 'ROLE_CASHIER',
    ROLE_EXTERNAL_ADMIN: 'ROLE_EXTERNAL_ADMIN',
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const adminRoot = '/app';

// export const domain = "http://127.0.0.1:6066"; // local
export const { domain } = Config; // server

export const servicePath = `${domain}/api/v1`; // local

export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const colors = [
    'orangecarrot',
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'redruby',
    'yellowgranola',
    'greysteel',
];

/* ******************** DEFAULT LISTING ******************** */

export const pageSizes = [4, 10, 20, 50, 100];

export const statusVisibility = [
    { column: -1, label: 'Tout' },
    { column: 1, label: 'Visible' },
    { column: 0, label: 'Invisible' },
];

export const statusOptions = [
    { column: 'ACTIVE', label: 'Actif' },
    { column: 'INACTIVE', label: 'Inactif' },
];

export const rolesOptions = [
    { column: 'ROLE_CLIENT', label: 'Client' },
    { column: 'ROLE_TAOO_ADMIN', label: 'Admin' },
    { column: 'ROLE_EXTERNAL_ADMIN', label: 'Partner Admin' },
    { column: 'ROLE_CASHIER', label: 'Caissier' },
    { column: 'ROLE_TAOO_OPERATOR', label: 'Opérateur' },
];

export const backendUserOrderByOptions = [
    { column: 'id', label: 'ID' },

    { column: 'lastName', label: 'Nom' },
    { column: 'updateAt', label: 'Date  de mise à jours' },
];

export const voucherOrderByOptions = [
    { column: 'id', label: 'ID' },
    { column: 'publishStartAt', label: 'Publish Start Date' },
    { column: 'publishEndAt', label: 'Publish End Date' },
];

export const invoicesOrderByOptions = [
    { column: 'id', label: 'ID' },
    { column: 'createAt', label: 'Created At' },
];

export const compnayOrderByOptions = [
    { column: 'id', label: 'ID' },
    { column: 'name', label: 'Nom' },

    { column: 'updateAt', label: 'Dernière mise à jour' },
];

export const statusOptionDeleted = { column: 'DELETED', label: 'Deleted' };

export const cardSizeOptions = [
    { label: 'Grande', value: 'BIG' },
    { label: 'Petite', value: 'SMALL' },
];

export const purchaseTypeOptions = [
    { label: 'Argent', value: 'MONEY_ONLY' },
    { label: 'Argent et points', value: 'MONEY_AND_POINTS' },
];

export const couponPurchaseTypeOptions = [
    { label: 'Argent', value: 'MONEY_ONLY' },
    { label: 'Points ', value: 'POINTS_ONLY' },
    { label: 'Argent and points', value: 'MONEY_AND_POINTS' },
];

export const discountTypeOptions = [
    { label: 'Pourcentage', value: 'PERCENT' },
    { label: 'Argent', value: 'MONEY' },
];

export const onlineCouponTypeOptions = [
    { label: 'Entreprise', value: 'COMPANY' },
    { label: 'Réduction', value: 'DISCOUNT' },
];

export const questionsLocalOptions = [
    { label: 'TN', value: 'TN' },
    { label: 'FR', value: 'FR' },
    { label: 'EN', value: 'EN' },
];

export const questionsTypes = [
    { label: 'single', value: 'SINGLE' },
    { label: 'multiple', value: 'MULTIPLE' },
];
