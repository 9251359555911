import { getCurrentUser } from "helpers/Utils";

import {
    CHECK_OTP_USER_SUCCESS,
    CHECK_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_CHECK_SUCCESS,
    LOGIN_USER_ERROR,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
} from "../contants";

const INIT_STATE = {
    currentUser: getCurrentUser(),
    newPassword: "",
    challenge: "",
    loading: false,
    role: "",
    error: "",
    userName: "N/A",
    forgotUserMail: "",
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case CHECK_USER:
        return { ...state, loading: true, error: "" };
    case LOGIN_USER:
        return { ...state, loading: true, error: "" };
    case CHECK_OTP_USER_SUCCESS:
        return {
            ...state,
            currentUser: {
                ...state.currentUser,
                challenge: action.payload.challenge,
            },
            error: "",
        };
    case LOGIN_USER_CHECK_SUCCESS:
        return {
            ...state,
            loading: false,
            currentUser: {
                ...state.currentUser,
                email: action.payload.login,
            },
            accountStatus: action.payload.status,
            error: "",
        };
    case LOGIN_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            currentUser: action.payload,
            error: "",
        };
    case LOGIN_USER_ERROR:
        return {
            ...state,
            loading: false,
            currentUser: null,
            error: action.payload.message,
        };
    case FORGOT_PASSWORD:
        return { ...state, loading: true, error: "" };
    case FORGOT_PASSWORD_SUCCESS:
        return {
            ...state,
            loading: false,
            forgotUserMail: action.payload.forgotUserMail,
            error: "",
        };
    case FORGOT_PASSWORD_ERROR:
        return {
            ...state,
            loading: false,
            forgotUserMail: "",
            error: action.payload.message,
        };
    case RESET_PASSWORD:
        return { ...state, loading: true, error: "" };
    case RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            loading: false,
            newPassword: action.payload,
            challenge: "",
            error: "",
        };
    case RESET_PASSWORD_ERROR:
        return {
            ...state,
            loading: false,
            newPassword: "",
            challenge: action.payload.resetPasswordCode,
            error: action.payload.message,
        };
    case LOGOUT_USER:
        return { ...state, currentUser: null, error: "" };
    default:
        return { ...state };
    }
};
