import { domain, themeRadiusStorageKey } from "constants/defaultValues";
import messages from "constants/messages.json";

export const mapOrder = (array, order, key) => {
    // eslint-disable-next-line func-names
    array.sort(function (a, b) {
        const A = a[key];
        const B = b[key];

        if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
            return 1;
        }

        return -1;
    });

    return array;
};

export const getDateWithFormat = () => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!

    const yyyy = today.getFullYear();

    if (dd < 10) {
        dd = `0${dd}`;
    }

    if (mm < 10) {
        mm = `0${mm}`;
    }

    return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
    const now = new Date();

    return `${now.getHours()}:${now.getMinutes()}`;
};

export const getCurrentRadius = () => {
    let currentRadius = "rounded";

    try {
        if (localStorage.getItem(themeRadiusStorageKey)) {
            currentRadius = localStorage.getItem(themeRadiusStorageKey);
        }
    } catch (error) {
        console.log(
            ">>>>: src/helpers/Utils.js : getCurrentRadius -> error",
            error
        );
        currentRadius = "rounded";
    }

    return currentRadius;
};

export const setCurrentRadius = (radius) => {
    try {
        localStorage.setItem(themeRadiusStorageKey, radius);
    } catch (error) {
        console.log(
            ">>>>: src/helpers/Utils.js : setCurrentRadius -> error",
            error
        );
    }
};

export const getCurrentUser = () => {
    let user = null;

    try {
        user =
      localStorage.getItem("taoo_current_user") != null
          ? JSON.parse(localStorage.getItem("taoo_current_user"))
          : null;
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
        user = null;
    }

    return user;
};

export const setCurrentUser = (user) => {
    try {
        if (user) {
            localStorage.setItem("taoo_current_user", JSON.stringify(user));
        } else {
            localStorage.removeItem("taoo_current_user");
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
    }
};

export const getCurrentUserName = () => {
    let user = null;

    try {
        user = localStorage.getItem("taoo_username") ?? null;
    } catch (error) {
        console.log(
            ">>>>: src/helpers/Utils.js  : getCurrentUserName -> error",
            error
        );
        user = null;
    }

    return user;
};

export const setCurrentUserName = (username) => {
    try {
        if (username) {
            localStorage.setItem("taoo_username", JSON.stringify(username));
        } else {
            localStorage.removeItem("taoo_username");
        }
    } catch (error) {
        console.log(
            ">>>>: src/helpers/Utils.js : setCurrentUserName -> error",
            error
        );
    }
};

export const getCurrentEmail = () => {
    let user = null;

    try {
        user = JSON.parse(localStorage.getItem("taoo_email")) ?? null;
    } catch (error) {
        console.log(
            ">>>>: src/helpers/Utils.js  : getCurrentEmail -> error",
            error
        );
        user = null;
    }

    return user;
};

export const setCurrentEmail = (email) => {
    try {
        if (email) {
            localStorage.setItem("taoo_email", JSON.stringify(email));
        } else {
            localStorage.removeItem("taoo_email");
        }
    } catch (error) {
        console.log(
            ">>>>: src/helpers/Utils.js : setCurrentEmail -> error",
            error
        );
    }
};

export const setToken = (token) => {
    try {
        if (token) {
            localStorage.setItem("taoo_token", JSON.stringify(token));
        } else {
            localStorage.removeItem("taoo_token");
        }
    } catch (error) {
        console.log(
            ">>>>: src/helpers/Utils.js : setToken -> error",
            error
        );
    }
};

export const setPageTitle = (title = null) => {
    document.title = `taoo${title ? ` — ${title}` : ""}`;
};

const prettyInt = (i) => {
    const x = `0${i}`;

    return x.substring(x.length - 2);
};

export const array2Date = (d) => {
    if (Array.isArray(d)) {
        return `${d[0]}/${prettyInt(d[1])}/${prettyInt(d[2])} ${prettyInt(
            d[3]
        )}:${prettyInt(d[4])}:${prettyInt(d[5])} `;
    }

    return d;
};

// eslint-disable-next-line no-underscore-dangle
export const __ = (key) => {
    if (!key) {
        return "null";
    }

    if (messages[key] ?? null) {
        return messages[key];
    }

    const keys = key.split(".");
    let leftKeys = [];
    let item = messages;

    while (keys.length) {
        leftKeys.push(keys.shift());
        item = item[leftKeys.join(".")] ?? messages;

        if (item) {
            const msg = item[keys.join(".")] ?? null;

            if (msg) {
                return msg;
            }

            leftKeys = [];
        }
    }

    // console.trace();
    // console.error(`>>>>: MISSING TRANSLATION : ${key}`);
    return key;
};

export const getImage = (image) => {
    if (image === "" || image === null) {
        return `/empty.png`;
    }

    return `${domain}/api/uploads/${image}`;
};
